<template>
  <section>
    <article class="container">
      <main class="card table-list">
        <h3>{{$t('block') + ' #' + height}}</h3>
        <!-- 区块详情 -->
        <ul class="layout-about-item-label" v-if="block">
          <li>
            <span>{{$t('height')}}</span>
            <p>{{height}}</p>
          </li>
          <li>
            <span>{{$t('blockTime')}}</span>
            <p>{{block.datetime?webUtil.timestampToDate(block.datetime):'--'}}</p>
          </li>
          <li>
            <span>{{$t('blockHash')}}</span>
            <p>{{block.hash?block.hash:'--'}}</p>
          </li>
          <li>
            <span>{{$t('totalExtrinsics')}}</span>
            <p>{{block.count_extrinsics?block.count_extrinsics:'0'}}</p>
          </li>
        </ul>
        <div class="null" v-show="isNull">{{$t('noInformation')}}</div>
        <loading :loading="loading"></loading>
      </main>
      <main class="card table-list">
        <h3>{{$t('extrinsics')}}</h3>
        <!-- 交易记录 -->
        <div class="table-responsive">
          <table v-if="extrinsics&&extrinsics.length>0">
            <thead>
              <tr>
                <th>{{$t('extrinsicID')}}</th>
                <th>{{$t('height')}}</th>
                <th>{{$t('module')}}</th>
                <th>{{$t('type')}}</th>
                <th>{{$t('status')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in extrinsics" :key="item.id">
                <td v-if="item.id">
                  <router-link :to="'/extrinsic/'+item.id">{{item.id}}</router-link>
                </td>
                <td v-else>--</td>
                <td>
                  <router-link
                    :to="'/block/'+item.attributes.block_id"
                  >{{item.attributes.block_id}}</router-link>
                </td>
                <td>{{item.attributes.module_id?item.attributes.module_id:'--'}}</td>
                <td>
                  <span class="type">{{item.attributes.call_id?item.attributes.call_id:'--'}}</span>
                </td>
                <td class="text-green" v-if="item.attributes.success">Success</td>
                <td class="text-orange" v-else>Fail</td>
              </tr>
            </tbody>
          </table>
          <div class="null" v-else>{{$t('noInformation')}}</div>
          <loading :loading="loading"></loading>
        </div>
      </main>
    </article>
  </section>
</template>

<script>
import loading from "base/loading";
export default {
  props: ["height"],
  data() {
    return {
      block: null,
      extrinsics: null,
      loading: true,
      isNull: false
    };
  },
  created() {
    this.getBlock();
  },
  methods: {
    getBlock() {
      this.loading = true;
      this.axios
        .get(
          this.domain +
            "v1/block/" +
            this.height +
            "?include=extrinsics"
        )
        .then(res => {
          this.block = res.data.data.attributes;
          this.extrinsics = res.data.included;
          this.loading = false;
          if (!this.block) {
            this.isNull = true;
          }
        })
        .catch(() => {
          console.log("Get block failed");
          this.loading = false;
          this.isNull = true;
          this.block = null;
          this.extrinsics = null;
        });
    }
  },
  components: {
    loading,
  },
  watch: {
    height(to) {
      this.getBlock();
    }
  }
};
</script>

<style scoped>
.trans_detail {
  min-height: 82px;
}
</style>
